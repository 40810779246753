import React from 'react';
import PropTypes from 'prop-types';
import PopupWindow, { closePopup } from '../../shared/PopupWindow';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';

const Block = styled('div')`
    width:100%;
    height:463px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding:0;
    .button-box
    {
        margin-top: 40px;
    }
    h2{ margin-bottom: 20px;}
    span{
      max-width:481px;
      font-size: 18px;
      line-height: 1.62;
      text-align: center;
      opacity: 0.8;
    }
    div {
      margin-top:40px;
      display:flex;
      flex-direction:row;
      .create-account{
        margin-right:17px;
        width:240px;
        height:48px;
        border-radius:24px;
        border: 1px solid ${color.veryDarkOrange};
        color:${color.veryDarkOrange};
        background-color:${color.transparent};
        display:flex;
        justify-content: center;
        align-items:center;
        text-decoration:none;
        font-size: 15px;
        font-weight: 500;
        &:hover{
          color:${color.black};
          background:linear-gradient(to left, #ff3e41, #ffc000);
        }
      }
      .back-to-provider{
        &:hover{
          color:${color.black};
          background:linear-gradient(to left, #ff3e41, #ffc000);
        }
        width:240px;
        height:48px;
        border-radius:24px;
        border: 1px solid ${color.veryDarkOrange};
        color:${color.veryDarkOrange};
        background-color:${color.transparent};
        display:flex;
        justify-content: center;
        align-items:center;
        text-decoration:none;
        font-size: 15px;
        font-weight: 500;
      }
    }
    .dont-show-checkbox {
      position:relative;
      margin-top: 120px;
      margin-bottom: -60px;
      .have-acc-checkbox {
        opacity:0;
      }
      .have-acc-checkbox-text{
        font-size: 18px;
        line-height: 1.62;
        letter-spacing: normal;
        text-align: center;
        color:${color.transparentWhite8}
      }
      label:before{
        border-radius: 6px;
        content: '';
        margin-right: 10px;
        margin-bottom: 3px;
        display: inline-block;
        vertical-align: text-bottom;
        width: 20px;
        height: 20px;
        border:1px solid ${color.transparentWhite3};
      }
      .have-acc-checkbox:checked + label:after{
        transform: rotate(45deg);
        content: '';
        position: absolute;
        left: 18px;
        top: 13px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 ${color.white}, 4px 0 0 ${color.white}, 4px -2px 0 ${color.white}, 4px -4px 0 ${color.white}, 4px -6px 0 ${color.white}, 4px -8px 0 ${color.white};
      }
    }
    @media (max-width:${breakpoints.md}) {
      h2{
        margin-top: -40px;
        font-size:22px;
        margin-left:0;
position:unset;
        max-width:280px;
        width:100%;
      }
      .popup-content {
        overflow:hidden;
      }
      >span {
        max-width:280px;
        font-size:14px;
      }
      .button-box {
        width:256px;
        display:flex;
        flex-direction:column;
        .create-account { 
          background:linear-gradient(to left, #ff3e41, #ffc000);
          color:${color.black};

          margin-bottom:24px;
          width:256px;
        }
        .back-to-provider{
          width:256px;
        }
      }
      .dont-show-checkbox{
        margin-top:40px;
        margin-bottom: -70px;
        width: 100%;
        max-width: 280px;
        font-size:15px;
        position:unset;
        margin-left:0;
      }
   }
`;

const hideAllErrors = () => {
  const errorBoxes = document.getElementsByClassName('errorMsg');
  if (errorBoxes.length) {
    Object.values(errorBoxes).forEach(function (element) {
      element.classList.remove('visible');
    });
  }
};

const closer = (popupId, urla) => {
  let dontShow = document.getElementById('have-acc-checkbox');
  localStorage.setItem('dontShow', dontShow.checked);
  closePopup(popupId);
  document.getElementById('have-acc').classList.remove('popup-open');
  window.location.assign(urla);
};


const HaveAccountPopup = ({ popupId, urla }) => {
  return (
    <PopupWindow
      popupId={popupId}
      extraClass="title-orange"
    >
      <Block>
        <h2>คุณมีบัญชีแล้วหรือยัง?</h2>
        <span>เพื่อให้คุณได้รับประสบการณ์การเล่นเกมที่สมบูรณ์แบบและสนุกที่สุด สร้างบัญชีเล่นเกมกับเราเพื่อเริ่มเล่น</span>
        <div className='button-box'>
          <a className='create-account' href="/register/?register_type=onsitegames">ฉันต้องการที่จะสร้างบัญชี</a>
          <button className='back-to-provider' onClick={() => closer(popupId, urla)}>ดาวน์โหลดโปรแกรม</button>
        </div>
        <div className='dont-show-checkbox'>
          <input type='checkbox' id='have-acc-checkbox' className='have-acc-checkbox'
            onClick={() => localStorage.setItem('dontShow', event.target.checked)} />
          <label className='have-acc-checkbox-text' htmlFor='have-acc-checkbox'>อย่าแสดงหน้านี้อีก</label>
        </div>
      </Block>
    </PopupWindow>
  );
};
export default HaveAccountPopup;


HaveAccountPopup.propTypes = {
  popupId: PropTypes.string,
  urla: PropTypes.string
};
