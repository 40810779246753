import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import styled from '@emotion/styled';
import {
    wrapper,
    wrapper1200,
    featuredWrapper,
    noFeaturedWrapper,
    color,
    breakpoints,
    font,
    ContentStyles,
    ContentStylesExt,
    Slick,
} from '../../utils/style';

const BottomLine = styled('div')`
    width:100%;
    height:3px;
    background-color: rgba(215,215,215,0.2);
    margin-top:-8px;
`;

const PageNames = styled('div')`

::-webkit-scrollbar{
    height:0px;
    background-color:rgba(215,215,215,0.2);
}
margin-top:24px;
width: 100%;
overflow:hidden;
display:flex;
padding-bottom:5px;
flex-direction:row;
justify-content: space-between;
.page-name-wrapper{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height:48px;
    &.active,
    &:hover{
        .page-name-a{
            color: #ffcdcd;
            text-shadow: 0 0 5px #d60000, 0 1px 4px #e02020;
        }
        .custom-border{
            background-color: #ffcdcd;
            box-shadow: 0 0 6px 0 #d60000, 0 0 4px 1px #d60000;
        }
    }
    .custom-border{
        height:3px;
        width:100%;
    }
    .page-name-a{
        white-space: nowrap;
        font-family: Kanit;
        font-size: 16px;
        font-weight: 600;
        text-transform:uppercase;
        text-align:center;
        text-decoration:none;
        height:48px;
        color: ${color.transparentWhite8};
        
    }
}
.clear-space{
    width:109px;
}
.mobile-filler{
   width:10vw;
    display:none;
}
@media (max-width: ${breakpoints.md}){
    
.page-name-a{
    width:auto;
}
width:100vw;
    overflow-x:scroll;
    .mobile-filler{
        min-width:10vw;
        display:block;
        &.last{
            min-width:30px;
        }
    }
}
`;

const PaginationSlider = () => {
    useEffect(() => {
        ['baccarat', 'roulette', 'slot', 'sicbo', 'dragon-tiger', 'fish-shooting'].map((item) => {
            if (window.location.pathname.indexOf(item, 0) !== -1) document.getElementById(item).classList.add('active')
        })

    }, []);
    return <>
        <PageNames>
            <div className='page-name-wrapper' id='baccarat'><a href='/baccarat/' className='page-name-a' >
                baccarat
            </a><div className='custom-border' /></div>
            <div className='mobile-filler' />
            <div className='page-name-wrapper' id='roulette'><a href='/roulette/' className='page-name-a'>
                roulette
            </a><div className='custom-border' /></div>
            <div className='mobile-filler' />
            <div className='page-name-wrapper' id='slot'><a href='/slot/' className='page-name-a'>
                slot
            </a><div className='custom-border' /></div>
            <div className='mobile-filler' />
            <div className='page-name-wrapper' id='sicbo'><a href='/sicbo/' className='page-name-a'>
                sicbo
            </a><div className='custom-border' /></div>
            <div className='mobile-filler' />
            <div className='page-name-wrapper' id='dragon-tiger'><a href='/dragon-tiger/' className='page-name-a'>
                dragon-tiger
            </a><div className='custom-border' /></div>
            <div className='mobile-filler' />
            <div className='page-name-wrapper' id='fish-shooting'><a href='/fish-shooting/' className='page-name-a'>
                fish-shooting
            </a><div className='custom-border' /></div>
            <div className='mobile-filler last' />

        </PageNames>
        <BottomLine />
    </>
}

export default PaginationSlider