import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { color } from '../../../utils/style';
import Img from 'gatsby-image';

const Wrapper = styled('div')`
`;

const GameAds = styled('ul')`
   list-style: none;
   margin-top: 10px;
`;

const GameAd = styled('li')`
  width: 23%;
  display: inline-block;
  vertical-align: top;
  margin: 10px 1%;
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  a {
    display: block;
    border-radius: 24px;
    border: 1px solid transparent;
    &:hover, &:active {
      border-color: ${color.yellow};
    }
  }
`;

export default () => (
  <StaticQuery
    query={graphql`{
    allWordpressWpDemo {
     edges {
       node {
         wordpress_id
         title
         path
         date(formatString: "MMMM DD, YYYY")
         featured_media {
           alt_text
           localFile {
             childImageSharp {
               fluid(maxWidth: 430) {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
         }

       }
     }
   }
   placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 430) {
          ...GatsbyImageSharpFluid_withWebp
        }
     }
   }

    }`}
    render={data => (
      <Wrapper>
        <GameAds>
          {data.allWordpressWpDemo.edges.map((item) =>
            <GameAd key={item.node.title}>
              <Link to={item.node.path}>
                {(item.node.featured_media && item.node.featured_media.localFile &&
                  item.node.featured_media.localFile.childImageSharp && item.node.featured_media.alt_text) ?
                  <Img fluid={item.node.featured_media.localFile.childImageSharp.fluid}
                       alt={item.node.title}/> :
                  <Img fluid={data.placeholder.childImageSharp.fluid}
                       alt="Demo Game"/>
                }
              </Link>
            </GameAd>
          )}</GameAds>
      </Wrapper>
    )}
  />
);
